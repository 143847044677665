import React, { useEffect, useState } from 'react';
import useIbanFormat from '../../../hooks/useIbanFormat';
import { TextInputState } from '../../../types/Input';
import { IbanInputProps } from './index.types';
import { isDesktopOrTablet, scrollToTopOfTheElementForMobile } from '../../../utils/helper';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';

const initialState: TextInputState = {
  focus: false,
  value: '',
  validation: {
    valid: true,
    message: '',
  },
};

const IbanInput = (props: IbanInputProps): JSX.Element => {
  const {
    className,
    validator,
    validation,
    placeholder,
    value: propsValue,
    onChange,
    onError,
  } = props;

  const [state, setState] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);

  const { formattedValue, handleIbanInput } = useIbanFormat(propsValue || state.value);

  const cleanInput = () => {
    onChange('');
    handleIbanInput({ target: { value: '' } }, true);
    setState({
      ...state,
      value: '',
    });
  };

  const handleOnChange = (e: any) => {
    handleIbanInput(e, state.value.length > e.target.value.length);
    setState({
      ...state,
      value: formattedValue,
      validation: validator?.validate(e.target.value) ?? initialState.validation,
    });
    onChange(e.target.value);

    if (validator && onError) {
      onError(validator.validate(e.target.value));
    }
  };

  const handleBlur = (e: any) => {
    setTimeout(() => {
      setState({
        ...state,
        focus: false,
        validation: validator?.validate(e.target.value) ?? initialState.validation,
      });
    }, 100);
  };

  useEffect(() => {
    if (propsValue) {
      handleIbanInput({ target: { value: propsValue } }, false);
      setState((oldState) => ({
        ...oldState,
        value: formattedValue,
      }));
    }
  }, [propsValue]);

  useEffect(() => {
    if (state.validation?.valid || state.focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [state.focus, state.validation]);

  useEffect(() => {
    if (validation) {
      setState({
        ...state,
        validation,
      });
    }
  }, [validation]);

  return (
    <div
      className={`text-input ${showErrors ? 'error ' : ''}${
        state.focus ? 'focus ' : ''
      }${className}`}
    >
      <div className='inner'>
        <input
          data-testid='iban-input'
          className={formattedValue ? 'has-value' : ''}
          type='text'
          value={formattedValue ?? ''}
          onChange={handleOnChange}
          onBlur={handleBlur}
          onFocus={(e) => {
            setState({ ...state, focus: true });
            scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
          }}
        />
        <div data-testid='iban-placeholder' className='floating-label'>{`${placeholder}`}</div>
        {!isDesktopOrTablet() && formattedValue && state.focus && (
          <div className='append-inner'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && (
        <div data-testid='iban-error-text' className='error-text'>
          {' '}
          {state.validation?.message}{' '}
        </div>
      )}
    </div>
  );
};

export default IbanInput;
