import React from 'react';
import { RecipientSearchModel } from '../../../../../types/Overview/Common';
import RecipientIcon from '../../../../../assets/recipient.png';
import AddRecipientIcon from '../../../../../assets/recipient-add.png';
import './index.scss';
import highlightSearchText from '../../../../../utils/highlightSearchText';

interface RecipientSearchItemProps {
  itemData: RecipientSearchModel;
  searchText: string;
}

const RecipientSearchItem = ({ itemData, searchText }: RecipientSearchItemProps) => (
  <div className='recipient-search-item' data-testid='recipient-search-item'>
    <div className='recipient-logo'>
      {itemData?.logo ||
        (itemData.id ? (
          <img src={RecipientIcon} alt='recipient' />
        ) : (
          <img src={AddRecipientIcon} alt='add recipient' />
        ))}
    </div>
    <div className='recipient-info'>
      <div className='recipient-name'>{highlightSearchText(itemData.name, searchText)}</div>
      <div className='recipient-details'>
        {itemData.postfach
          ? `Postfach ${itemData.postfach},`
          : `${itemData.street || ''}${
              itemData.streetNum ? ` ${itemData.streetNum}` : ''
            }${(itemData.streetNum || itemData.street) && (itemData.postalCode || itemData.city) ? ',' : ''}`}

        {itemData.postalCode && itemData.city ? ` ${itemData.postalCode}, ${itemData.city}` : ''}
      </div>
    </div>
  </div>
);

export default RecipientSearchItem;
