import { create } from 'zustand';
import { FrontendRule, FrontEndSection, Hint, HintTypes } from './FrontendRule.type';

interface FrontendRuleState {
  frontendRule: FrontendRule | undefined;
  setFrontendRule: (rule: FrontendRule | undefined) => void;
  decideSectionToShow: (sectionName: FrontEndSection) => boolean;
  decideHintToShow: ({ type, hint, itemId }: Hint) => boolean;
}

export const useFrontendRuleStore = create<FrontendRuleState>((set, get) => ({
  frontendRule: undefined,

  setFrontendRule: (rule) => set({ frontendRule: rule }),

  decideSectionToShow: (sectionName) => {
    const { frontendRule } = get();
    return !frontendRule?.hideSections?.includes(sectionName);
  },

  decideHintToShow: ({ type, hint, itemId }) => {
    const { frontendRule } = get();
    return !!frontendRule?.hints?.filter(
      (h: any) =>
        h.type === type &&
        h.hint === hint &&
        (type === HintTypes.NOTIFICATION ? h.itemId === itemId : true),
    )?.length;
  },
}));
