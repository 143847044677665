import React, { useState } from 'react';
import './index.scss';

import { useMutation } from '@tanstack/react-query';
import Container from '../../../../Common/Container';
import { Button } from '../../../../Common/Button';
import { ValidationResult } from '../../../../../utils/validators';
import ServiceGateway from '../../../../../services/ServiceGateway';
import { useKwsState } from '../../../../../contexts/KwsStateContext/KwsStateContext';
import Tooltip from '../../../../Common/Tooltip';
import { useOverview } from '../../../../../contexts/OverviewContext/OverviewContext';
import IbanInput from '../../../../Common/IbanInput';
import { TooltipStateEnum } from '../../../../../contexts/TooltipStateContext/Tooltip.types';
import InfoBox from '../../../../Common/InfoBox';

interface MissingIbanSectionProps {
  onSubmit: () => void;
}

const MissingIbanSection = ({ onSubmit }: MissingIbanSectionProps): JSX.Element => {
  const [iban, setIban] = useState('');
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});
  const { kwsState, refreshKwsState } = useKwsState();
  const { overviewData } = useOverview();
  const { closingData } = overviewData!;

  const { mutate: submit, isPending } = useMutation({
    mutationFn: () => ServiceGateway.attachTargetIban(kwsState!.id, iban),
    onSuccess: () => {
      sessionStorage.setItem('scrollPosition', '0');
      refreshKwsState();
      onSubmit();
    },
    onError: (error: any) => {
      if (error.response?.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'IBAN') {
                return {
                  ...acc,
                  [err.key]: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    },
  });

  return (
    <Container>
      <div className='missing-iban-section'>
        <div className='title' data-testid='missing-iban-header'>
          <h2>Neue IBAN nachreichen</h2>

          <Tooltip tooltipSectionName={TooltipStateEnum.MISSING_IBAN}>
            <p>
              Der Kontoumzug kann erst nach Eingabe der IBAN Ihres neuen Kontos ausgeführt werden.
              Sollte Ihnen die IBAN noch nicht vorliegen, können Sie Ihren bereits erfassten
              Fortschritt über „Speichern und abmelden“ sichern und die IBAN später nachtragen.
              Klicken Sie hierzu einfach erneut auf den Einladungslink in der E-Mail.
            </p>
          </Tooltip>
        </div>
        <InfoBox title='Wir benötigen noch Ihre neue Girokonto IBAN' type='alert' />
        <div className='text' data-testid='missing-iban-text'>
          Leider hat uns die <b>{closingData?.newBank?.name ?? 'Bank'}</b> Ihre neue IBAN nicht
          automatisch übermittelt. Bitte geben Sie Ihre <b>Girokonto IBAN</b> hier ein, damit Ihr
          Kontoumzug durchgeführt werden kann.
        </div>
        <div className='form-group'>
          <div className='form-input' data-testid='missing-iban-input'>
            <IbanInput
              placeholder='Neue IBAN'
              value={iban}
              onChange={(v) => setIban(v)}
              validation={validationError.iban}
            />
          </div>
          {/* CTA */}
          <div className='button' data-testid='missing-iban-submit'>
            <Button onClick={submit} label='IBAN speichern' loading={isPending} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MissingIbanSection;
