/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './index.scss';

interface CheckboxProps {
  checked: boolean;
  inputId: string;
  disabled?: boolean;
  /** Control checkbox state in the upper level */
  onChange?: (v: boolean) => void;
  label?: string;
}

const Checkbox = ({ checked, inputId, disabled, label, onChange }: CheckboxProps) => {
  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <div
      className={`checkbox-container ${label ? 'has-label' : ''}`}
      data-testid='checkbox-container'
    >
      <input
        type='checkbox'
        id={inputId}
        disabled={disabled}
        checked={!disabled && checked}
        onChange={handleChange}
        data-testid='checkbox-input-field'
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  );
};

export default Checkbox;
