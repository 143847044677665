import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import './index.scss';
import ServiceGateway, { logoutSystem } from '../../../services/ServiceGateway';
import ContactCard from '../../Common/ContactCard';
import SuccessSection from './sections/SuccessSection/index';
import ReviewSection from './sections/ReviewSection/index';
import NotificationsSection from '../CommonSections/Notifications/index';
import StandingOrdersSection from '../CommonSections/StandingOrders';
import AccountClosingSection from '../CommonSections/AccountClosing/index';
import GuaranteeInfoSection from '../CommonSections/GuaranteeInfo/index';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import { useOverview } from '../../../contexts/OverviewContext/OverviewContext';
import MissingIbanSection from './sections/MissingIbanSection';
import {
  getLastScrollPosition,
  removeModifiedTransaction,
  scrollToPosition,
} from '../../../utils/helper';
import ToggleSection from './sections/ToggleSection';
import AlertSection from './sections/AlertSection';
import SpinnerFullPage from '../../Common/SpinnerFullPage';
import FidorBankHintSection from './sections/FidorBankHintSection';
import { ProcessStateEnum, WorkflowEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import TimelineSection from './sections/TimelineSection';
import { ItemState } from '../../../types/Overview/Common';
import ActionButtons from '../../Common/ActionButtons';
import { NotificationModel } from '../../../types/Overview/NotificationModel';
import { StandingOrderModel } from '../../../types/Overview/StandingOrderModel';
import { UserStatusEnum } from '../../../types/SignaturePage/SignaturePageModel';
import {
  FrontEndSection,
  HintTypes,
  HintSection,
} from '../../../stores/FrontendRule/FrontendRule.type';
import { useFrontendRuleStore } from '../../../stores/FrontendRule/FrontendRule.store';

const Thanks = () => {
  const [loading, setLoading] = useState(false);
  const { kwsState } = useKwsState();
  const { overviewData, fillOverviewData } = useOverview();
  const [successState, setSuccessState] = useState<UserStatusEnum>(() => {
    const userStatus = sessionStorage.getItem('c24-ready-status');
    return userStatus ? UserStatusEnum.READY : UserStatusEnum.DEFAULT;
  });

  const { decideHintToShow, decideSectionToShow } = useFrontendRuleStore(
    useShallow((state) => ({
      decideHintToShow: state.decideHintToShow,
      decideSectionToShow: state.decideSectionToShow,
    })),
  );

  useEffect(() => {
    const scrollPosition = getLastScrollPosition();
    scrollToPosition(scrollPosition);
  }, [overviewData]);

  const getThanksPageData = async () => {
    const response = await ServiceGateway.getThanksPage(kwsState!.id);
    fillOverviewData(response.data);
  };

  const submitIban = () => {
    getThanksPageData();
    setSuccessState(UserStatusEnum.UPDATED);
  };

  const submit = async () => {
    setLoading(true);
    await ServiceGateway.approveProcess(kwsState!.id);
    sessionStorage.setItem('scrollPosition', '0');
    getThanksPageData();
    setSuccessState(UserStatusEnum.UPDATED);
    setLoading(false);
  };

  useEffect(() => {
    getThanksPageData();
    sessionStorage.removeItem('c24-ready-status');

    return () => removeModifiedTransaction();
  }, []);

  if (!overviewData) {
    return <SpinnerFullPage />;
  }

  const isCTAButtonEnabled =
    overviewData.notifications?.some(
      (notif: NotificationModel) => notif.state === ItemState.NOTIFY,
    ) ||
    overviewData.standingOrders?.some(
      (notif: StandingOrderModel) => notif.state === ItemState.NOTIFY,
    ) ||
    overviewData.closingData.state === ItemState.NOTIFY;

  return (
    <div className='thanks-page' data-testid='thanks-page'>
      {decideSectionToShow(FrontEndSection.SUCCESS) && <SuccessSection userState={successState} />}

      {kwsState!.state === ProcessStateEnum.WAITING_FOR_IBAN_FROM_CUSTOMER
        ? decideSectionToShow(FrontEndSection.MISSING_IBAN) && (
            <MissingIbanSection onSubmit={submitIban} />
          )
        : null}

      {decideHintToShow({
        type: HintTypes.SECTION,
        hint: HintSection.FIDOR_BANK_PROMOTION,
      }) && <FidorBankHintSection />}

      {decideSectionToShow(FrontEndSection.ALERT) && <AlertSection />}

      {decideSectionToShow(FrontEndSection.REVIEW) && <ReviewSection />}

      <ToggleSection>
        {decideSectionToShow(FrontEndSection.NOTIFICATIONS) && <NotificationsSection />}

        {decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
          !(kwsState?.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN) && <StandingOrdersSection />}

        {decideSectionToShow(FrontEndSection.CLOSE_ACCOUNT) && <AccountClosingSection />}
      </ToggleSection>

      {decideSectionToShow(FrontEndSection.CTA_BUTTON) && (
        <ActionButtons
          isSticky
          isLoading={loading}
          proceedButton={
            isCTAButtonEnabled ? { label: 'Kontoumzug aktualisieren', onClick: submit } : undefined
          }
          cancelButton={{ label: 'speichern und abmelden', onCancel: logoutSystem }}
        />
      )}

      {decideSectionToShow(FrontEndSection.TIMELINE) && overviewData?.timeline && (
        <TimelineSection timeline={overviewData.timeline} />
      )}

      {decideSectionToShow(FrontEndSection.GUARANTEE_INFO) && <GuaranteeInfoSection />}

      {decideSectionToShow(FrontEndSection.CONTACT_CARD) && <ContactCard />}
    </div>
  );
};

export default Thanks;
