enum FrontEndSection {
  WELCOME = 'WELCOME',
  SWITCHING_DATE = 'SWITCHING_DATE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  STANDING_ORDERS = 'STANDING_ORDERS',
  CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  CTA_BUTTON = 'CTA_BUTTON',
  GUARANTEE_INFO = 'GUARANTEE_INFO',
  CONTACT_CARD = 'CONTACT_CARD',
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
  SUCCESS = 'SUCCESS',
  MISSING_IBAN = 'MISSING_IBAN',
  ALERT = 'ALERT',
  REVIEW = 'REVIEW',
  TIMELINE = 'TIMELINE',
}

enum HintSection {
  FIDOR_BANK_PROMOTION = 'FIDOR_BANK_PROMOTION',
  WE_PUT_NEW_IBAN_TO_SSO = 'WE_PUT_NEW_IBAN_TO_SSO',
  TARGET_BANK_DOESNT_SUPPORT_STANDING_ORDERS = 'TARGET_BANK_DOESNT_SUPPORT_STANDING_ORDERS',
  SOURCE_BANK_DOESNT_SUPPORT_STANDING_ORDERS = 'SOURCE_BANK_DOESNT_SUPPORT_STANDING_ORDERS',
  SOURCE_BANK_DOESNT_SUPPORT_CLOSE_ACCOUNT = 'SOURCE_BANK_DOESNT_SUPPORT_CLOSE_ACCOUNT',
  SSO_IBAN_CHANGE_NOT_POSSIBLE_FOR_C24 = 'SSO_IBAN_CHANGE_NOT_POSSIBLE_FOR_C24',
}

enum HintTypes {
  NOTIFICATION = 'NOTIFICATION',
  SECTION = 'SECTION',
}

interface Hint {
  type: HintTypes;
  hint: HintSection;
  afterSection?: string;
  itemId?: string;
}

export interface FrontendRule {
  hideSections?: FrontEndSection[];
  hints?: Hint[];
}

interface IFrontendRuleStateProvider {
  frontendRule?: FrontendRule;
  setFrontendRule: (val: FrontendRule | undefined) => void;
  decideSectionToShow: (sectionName: FrontEndSection) => boolean;
  decideHintToShow: (hint: Hint) => boolean;
  children?: React.ReactNode;
}

export { IFrontendRuleStateProvider, Hint, HintTypes, HintSection, FrontEndSection };
