import React from 'react';
import './index.scss';
import { isDesktopOrTablet } from '../../../../../utils/helper';
import { PossibleBank } from '../../../../../services/ServiceGateway.types';
import { ReactComponent as BankLogo } from '../../../../../assets/banks/bank-default.svg';
import highlightSearchText from '../../../../../utils/highlightSearchText';

interface BankSearchItemProps {
  itemData: PossibleBank;
  searchText: string;
}

const BankSearchItem = ({ itemData, searchText }: BankSearchItemProps): React.JSX.Element => (
  <div className='bank-search-item' data-testid='bank-search-item'>
    <div className={`bank-logo${itemData?.logoUrl ? '' : ' with-circle'}`}>
      {itemData?.logoUrl ? <img src={itemData?.logoUrl} alt={itemData.name} /> : <BankLogo />}
    </div>
    <div className='bank-info'>
      <div className='bank-name'>{highlightSearchText(itemData.name, searchText)}</div>
      <div className='bank-details'>
        BLZ: {highlightSearchText(itemData.code, searchText)} | BIC:{' '}
        {highlightSearchText(itemData.bic || '', searchText)}{' '}
        {isDesktopOrTablet() ? <>| </> : <br />} Standort:{' '}
        {highlightSearchText(itemData.city || '', searchText)}
      </div>
    </div>
  </div>
);

export default BankSearchItem;
