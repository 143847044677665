import React, { useState } from 'react';
import './index.scss';

export type Tab = { id: number; title: string; content: React.JSX.Element };

type TabButtonsProps = {
  tabs: Tab[];
  startingSelectedTab?: number;
  classNames?: string;
};

/**
 * TabButtons component sticks to the block element that is on top of it.
 * It takes an array of tabs and renders them as buttons, and the content of the selected tab.
 *
 * ! The tab id needs to start from 0 and be consecutive.
 */
const TabButtons = ({
  tabs,
  startingSelectedTab,
  classNames,
}: TabButtonsProps): React.JSX.Element => {
  const [selectedTabId, setSelectedTab] = useState(startingSelectedTab || 0);

  if (tabs.length < 2) return tabs[selectedTabId].content;

  return (
    <div className='tab-wrapper' data-testid='tab-buttons'>
      <div className={`tabs-section ${classNames}`}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-item ${selectedTabId === tab.id ? 'underline' : ''}`}
            onClick={() => setSelectedTab(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {tabs[selectedTabId].content}
    </div>
  );
};

export default TabButtons;
