import React from 'react';
import ChipButton, { ChipButtonProps } from '../ChipButton';
import './index.scss';

interface ChipButtonGroupProps<T> {
  buttons: ChipButtonProps<T>[];
  groupLabel?: string;
}

const ChipButtonGroup = <T,>({ buttons, groupLabel }: ChipButtonGroupProps<T>) => (
  <div className='chip-button-group' data-testid='chip-button-group'>
    {groupLabel && <div className='group-label'>{groupLabel}</div>}
    <div className='chip-wrapper'>
      {buttons.map((button) => (
        <ChipButton
          key={button.label}
          label={button.label}
          value={button.value}
          onClick={button.onClick}
          isActive={button?.isActive}
          hasError={button?.hasError}
        />
      ))}
    </div>
  </div>
);

export default ChipButtonGroup;
