import React from 'react';
import './index.scss';

export interface ChipButtonProps<T> {
  label: string;
  value: T;
  onClick: (value: T) => void;
  isActive?: boolean;
  hasError?: boolean;
}

const ChipButton = <T,>({ label, value, onClick, isActive, hasError }: ChipButtonProps<T>) => (
  <button
    type='button'
    className={`chip-button${isActive ? ' active' : ''}${hasError ? ' error' : ''}`}
    data-testid='chip-button'
    onClick={() => onClick(value)}
  >
    {label}
  </button>
);

export default ChipButton;
