import React, { useRef } from 'react';
import './index.scss';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import { ReactComponent as HelpIcon } from '../../../assets/help.svg';
import { useTooltipState } from '../../../contexts/TooltipStateContext/TooltipStateContext';
import { TooltipStateEnum } from '../../../contexts/TooltipStateContext/Tooltip.types';

interface TooltipProps {
  children?: React.ReactNode;
  tooltipSectionName: TooltipStateEnum;
  hasBackground?: boolean;
}

const Tooltip = ({ children, tooltipSectionName, hasBackground }: TooltipProps) => {
  const { tooltipState, setTooltipState } = useTooltipState();
  const tooltipRef = useRef<HTMLDivElement>(null);

  const preventBubbling = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClick = () => {
    if (tooltipSectionName === tooltipState) {
      setTooltipState(undefined);
    } else {
      setTooltipState(tooltipSectionName);
    }
  };

  const isCorrectTooltip = tooltipState === tooltipSectionName;

  return (
    <div className='tooltip-wrapper' data-testid='tooltip-item' ref={tooltipRef}>
      <HelpIcon
        className={`help-icon ${isCorrectTooltip ? 'focused' : ''}`}
        onClick={handleClick}
      />
      <div
        className={`desktop-only tooltip ${isCorrectTooltip ? 'tooltip-shown' : ''} ${
          hasBackground ? 'detail-page' : ''
        }`}
      >
        {children}
      </div>
      <div
        className={`mobile-only overlay ${isCorrectTooltip ? 'shown' : ''}`}
        onClick={handleClick}
        data-testid='tooltip-item-mobile'
      >
        <div className='popup'>
          <CrossIcon className='close' />
          <div className='content' onClick={preventBubbling}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tooltip;
