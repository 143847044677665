import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../TextInput';
import DesktopAutoComplete from './components/DesktopAutoComplete';
import Modal from '../Modal';
import Spinner from '../Spinner';
import './index.scss';
import { isDesktopOrTablet } from '../../../utils/helper';

interface AutoCompleteProps<T> {
  searchText: string;
  setSearchText: (val: string, valid?: boolean) => void;
  displayedValue: string;
  placeholder: string;
  loading: boolean;
  /** Search results for autocomplete field */
  possibleInputList: T[];
  selectPossibleInput: (result?: T) => void;
  /** Pass a component for display UI */
  renderItemDisplayValue: (result: T) => JSX.Element;
  setPossibleInputList?: React.Dispatch<React.SetStateAction<T[]>>;
  errorMessage?: string;
  setErrorMessage?: (value: string) => void;
  /** If the search input is selectable, pass a component as display UI */
  isSearchInputSelectable?: {
    renderSelectableDisplayValue: (value: string) => JSX.Element;
  };
  searchMinLength?: number;
}

const AutoComplete = <T,>({
  searchText,
  setSearchText,
  displayedValue,
  placeholder,
  loading,
  possibleInputList,
  selectPossibleInput,
  setPossibleInputList,
  renderItemDisplayValue,
  isSearchInputSelectable,
  errorMessage,
  setErrorMessage,
  searchMinLength,
}: AutoCompleteProps<T>): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (showModal === true) {
      searchInputRef.current?.focus();

      if (setErrorMessage) {
        setErrorMessage('');
      }
    }
  }, [showModal]);

  return (
    <>
      <div data-testid='auto-complete' className='auto-complete'>
        {isDesktopOrTablet() ? (
          <DesktopAutoComplete
            className={`w-full ${errorMessage ? 'error' : ''}`}
            result={possibleInputList}
            loading={loading}
            value={displayedValue}
            onChange={setSearchText}
            inputRef={searchInputRef}
            placeholder={placeholder}
            onResultClick={(selection) => {
              selectPossibleInput(selection);
              setShowModal(false);
            }}
            onKeyDown={(event) => {
              if (isSearchInputSelectable && setPossibleInputList) {
                if (event.key === 'Enter') {
                  selectPossibleInput();
                }
              }
            }}
            renderDisplayValue={renderItemDisplayValue}
            isSearchInputSelectable={isSearchInputSelectable}
            onClick={isSearchInputSelectable ? () => selectPossibleInput() : undefined}
            searchMinLength={searchMinLength ?? undefined}
            onClickOutside={() => {
              if (setPossibleInputList) {
                setPossibleInputList([]);
              }
              if (isSearchInputSelectable) {
                selectPossibleInput();
              }
            }}
          />
        ) : (
          <TextInput
            className={`w-full ${errorMessage ? 'error' : ''}`}
            onClick={() => setShowModal(true)}
            value={displayedValue}
            placeholder={placeholder}
            disabledJustInput
          />
        )}
        {errorMessage && <div className='error-text'> {errorMessage} </div>}
      </div>
      {showModal && (
        <Modal
          appearance='full'
          onClose={() => {
            setShowModal(false);
            selectPossibleInput();
            if (setErrorMessage) {
              setErrorMessage('');
            }
          }}
          backLabel='zurück'
        >
          <div className='selection-modal' data-testid='modal'>
            <div className='search-field'>
              <TextInput
                value={searchText}
                onChange={setSearchText}
                inputRef={searchInputRef}
                placeholder={placeholder}
                onKeyDown={(event) => {
                  if (isSearchInputSelectable) {
                    if (event.key === 'Enter') {
                      setShowModal(false);
                    }
                  }
                }}
              />
              {errorMessage && <div className='error-text'> {errorMessage} </div>}
              {((possibleInputList && possibleInputList.length > 0) ||
                (isSearchInputSelectable && searchText.length > 0)) && (
                <div className='possible-list'>
                  {isSearchInputSelectable && searchText && (
                    <div
                      className='possible-item'
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      onClick={() => {
                        selectPossibleInput();
                        setShowModal(false);
                      }}
                      data-testid='user-input'
                    >
                      {isSearchInputSelectable.renderSelectableDisplayValue(searchText)}
                    </div>
                  )}

                  {possibleInputList?.map((possibleItem: T, index) => (
                    <div
                      key={`possibleItem-${index}`}
                      className='possible-item'
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      onClick={() => {
                        selectPossibleInput(possibleItem);
                        setShowModal(false);
                      }}
                      data-testid='possible-input'
                    >
                      <div key={`possibleItem-${index}`}>
                        {renderItemDisplayValue(possibleItem)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {loading && <Spinner />}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AutoComplete;
