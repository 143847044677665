import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash.isequal';
import { TextInputState } from '../../../types/Input';
import { ValidationResult, Validator } from '../../../utils/validators';
import './index.scss';
import {
  isDesktopOrTablet,
  scrollToPosition,
  scrollToTopOfTheElementForMobile,
} from '../../../utils/helper';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';

interface TextInputProps {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  validator?: Validator;
  validation?: ValidationResult;
  className?: string;
  value: string;
  onChange?: (v: string, valid?: boolean) => void;
  onError?: (validation: ValidationResult) => void;
  onBlurChange?: (value: string) => void;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: any;
  inputRegex?: RegExp;
  inputMode?: any;
  onClick?: () => void;
  onKeyDown?: (event: any) => void;
  optional?: boolean;
  optionalText?: string;
}

const initialState: TextInputState = {
  focus: false,
  value: '',
  validation: {
    valid: true,
    message: '',
  },
};

const TextInput = (props: TextInputProps) => {
  const {
    disabled,
    disabledJustInput,
    disableErrorText,
    placeholder,
    validation,
    validator,
    value: propsValue,
    className,
    onChange,
    onBlurChange,
    onError,
    onFocusChange,
    onClick,
    onKeyDown,
    optional,
    optionalText,
    inputMode,
    inputRef,
    inputRegex,
  } = props;
  const [state, setState] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const prevPropsValidation = useRef<ValidationResult>();

  const handleChange = (e: any) => {
    const valueV = e.currentTarget.value || '';
    const regexValue = inputRegex ? valueV.replace(inputRegex, '') : valueV;
    const validationV = validator?.validate(regexValue);
    setState({
      ...state,
      value: regexValue || null,
      validation: validationV ?? initialState.validation,
    });
    if (onChange) {
      onChange(regexValue || null, validationV?.valid);
    }
    if (onError && validationV) {
      if (!validationV.valid) {
        onError(validationV);
      } else {
        onError({ valid: true });
      }
    }
  };

  const cleanInput = () => {
    if (onChange) {
      onChange('');
    }
    if (onBlurChange) {
      onBlurChange('0');
    }
  };

  useEffect(() => {
    if (state.validation?.valid || state.focus) {
      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }, [state.focus, state.validation]);

  useEffect(() => {
    if (validation && !isEqual(prevPropsValidation.current, validation)) {
      prevPropsValidation.current = validation;
      setState({
        ...state,
        validation,
      });
    }
  }, [validation]);

  useEffect(() => {
    if (typeof propsValue !== 'undefined') {
      setState((oldState) => ({
        ...oldState,
        value: propsValue,
      }));
    }
  }, [propsValue]);

  return (
    <div
      className={`text-input
        ${showErrors ? ' error' : ''}
        ${state.focus ? ' focus' : ''}
        ${disabled ? ' disabled' : ''}
        ${className ? ` ${className}` : ''}`}
      data-testid='text-input'
    >
      <div className='inner'>
        <input
          className={state.value ? 'has-value' : ''}
          type='text'
          data-testid='text-test'
          inputMode={inputMode ?? 'text'}
          value={state.value ?? ''}
          onChange={handleChange}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onKeyDown={(v) => {
            if (onKeyDown) onKeyDown(v);
          }}
          onFocus={(e) => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            setState({ ...state, focus: true });
            // inputRef is passed if component is in a modal, order of functions important in that case
            if (!inputRef?.current) {
              scrollToTopOfTheElementForMobile(window.scrollY, e.target.getBoundingClientRect().y);
            } else {
              scrollToPosition('0');
            }
          }}
          onBlur={() => {
            if (onFocusChange) {
              onFocusChange(false);
            }
            if (onBlurChange) {
              onBlurChange(state.value);
            }
            setTimeout(() => {
              setState((oldState) => ({ ...oldState, focus: false }));
            }, 100);
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={inputRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !state.value && !state.focus ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {!isDesktopOrTablet() && state.value && !disabled && state.focus && (
          <div className='append-inner'>
            <CrossIcon onClick={cleanInput} />
          </div>
        )}
      </div>
      {showErrors && !disableErrorText && (
        <div className='error-text'> {state.validation?.message} </div>
      )}
    </div>
  );
};

export default TextInput;
