import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import Spinner from '../../Common/Spinner';
import ServiceGateway from '../../../services/ServiceGateway';
import './index.scss';
import { useKwsState } from '../../../contexts/KwsStateContext/KwsStateContext';
import useDebounce from '../../../hooks/useDebounce';
import Container from '../../Common/Container';
import ContactCard from '../../Common/ContactCard';
import ProgressBar from '../../Common/ProgressBar';
import { ProcessStateEnum } from '../../../contexts/KwsStateContext/KwsState.types';
import InfoBox from '../../Common/InfoBox';
import Toggle from '../../Common/Toggle';
import Tooltip from '../../Common/Tooltip';
import { isProd } from '../../../utils/environment';
import { TooltipStateEnum } from '../../../contexts/TooltipStateContext/Tooltip.types';
import { DacPageModel } from '../../../types/DacPage/DacPageModel';
import WelcomeSection from './sections/WelcomeSection';
import FunctionSection from './sections/FunctionsSection';
import FinApiContainer from './sections/FinApiWebForm';
import ManualKWS from './sections/ManualKws';
import TinkContainer from './sections/TinkWidget';
import AutoComplete from '../../Common/Autocomplete';
import { DacProvider } from '../../../types/DacProvider';
import { DeviceOutputEnum } from '../../../utils/deviceOutput';
import { scrollToElement } from '../../../utils/helper';
import { PossibleBank } from '../../../services/ServiceGateway.types';
import { ConfirmationOrErrorModel } from '../../../types/ConfirmationErrorModel';
import ConfirmationModal from '../CommonSections/ConfirmationModal';
import BankSearchItem from '../../Common/Autocomplete/components/BankSearchItem';

const testBankList: PossibleBank[] = [
  {
    bic: 'KWSDE111XXX',
    city: 'KWS',
    code: '88888888',
    logoUrl: '',
    name: 'Tink Test Bank 1',
    score: 0,
  },
  {
    bic: 'KWSDE222XXX',
    city: 'KWS',
    code: '88888889',
    logoUrl: '',
    name: 'Tink Test Bank 2',
    score: 0,
  },
  {
    bic: 'KWSDE333XXX',
    city: 'KWS',
    code: 'DEMO0001',
    logoUrl: '',
    name: 'FINAPI Test Bank Embedded/Decoupled',
    score: 0,
  },
  {
    bic: 'KWSDE444XXX',
    city: 'KWS',
    code: 'DEMO0002',
    logoUrl: '',
    name: 'FINAPI Test Bank Redirect',
    score: 0,
  },
];

const Dac = () => {
  const { kwsState, refreshKwsState } = useKwsState();
  const [dacPageModel, setDacPageModel] = useState<DacPageModel>();

  const [processingTime, setProcessingTime] = useState<number>();
  const [processingCompleted, setProcessingCompleted] = useState(false);

  const [finApiToken, setFinApiToken] = useState<string>();
  const [tinkWizardKey, setTinkWizardKey] = useState<string>();

  const [providerError, setProviderError] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);

  const [showManualToggle, setShowManualToggle] = useState(false);
  const [expandManual, setExpandManual] = useState(false);
  const manualKwsRef = useRef<HTMLDivElement>(null);

  const [loadingBanks, setLoadingBanks] = useState(false);
  const [possibleBankList, setPossibleBankList] = useState<PossibleBank[]>([]);
  const [bankSearchText, setBankSearchText] = useState<string>('');
  const [bankData, setBankData] = useState<PossibleBank>();
  const [bankErrorMessage, setBankErrorMessage] = useState<any>();
  const [bankSelectorDisplay, setBankSelectorDisplay] = useState('');
  const debouncedBankSearchText = useDebounce<string>(bankSearchText, 1000);

  const [ibanEqualError, setIbanEqualError] = useState<
    ConfirmationOrErrorModel & { provider: DacProvider }
  >();

  const renderBankSelectorDisplay = (selectedBank: PossibleBank) => selectedBank.name.toString();

  const renderPossibleBankDisplayValue = (data: PossibleBank) => (
    <BankSearchItem itemData={data} searchText={bankSearchText} />
  );

  const setBankSearchWithIbanValidation = (value: string) => {
    // clean iban from spaces if minimum length is reached
    if (value?.length > 18) {
      const regexForIbanStart = /^[A-Za-z]{2}\d{2}/;

      if (regexForIbanStart.test(value)) {
        const cleanedValue = value.split(' ').join('');
        setBankSearchText(cleanedValue);
        return;
      }
    }

    setBankSearchText(value);
  };

  const getPossibleBankList = async (term: string) => {
    setLoadingBanks(true);
    setPossibleBankList([]);
    setBankErrorMessage(undefined);

    const { data: bankList } = await ServiceGateway.getPossibleBankList(term);

    if (!isProd()) {
      bankList.unshift(...testBankList);
    }

    if (bankList.length === 0) {
      setBankErrorMessage('Bank nicht verfügbar. Bitte überprüfen Sie Ihre Eingabe.');
    }

    setPossibleBankList(bankList);
    setLoadingBanks(false);
  };

  useEffect(() => {
    if (debouncedBankSearchText?.length > 1) {
      getPossibleBankList(debouncedBankSearchText);
      if (!showManualToggle) {
        setShowManualToggle(true);
      }
    } else {
      setPossibleBankList([]);
    }
  }, [debouncedBankSearchText]);

  const checkState = async () => {
    const fetchDacResult = await ServiceGateway.getProcessState(kwsState!.id);
    if (fetchDacResult.data.processState === ProcessStateEnum.EDITING) {
      setProcessingCompleted(true);

      setTimeout(() => {
        refreshKwsState();
      }, 500);
    } else {
      setTimeout(() => {
        checkState();
      }, 1000);
    }
  };

  const checkIbanEquality = (error: any, provider: DacProvider) => {
    if (error?.request?.status === 400) {
      const data = error?.response?.data;
      if (data?.type === 'IBAN_EQUALITY') {
        setIbanEqualError({
          provider,
          title: data.title,
          message: data.message,
        });
      }
    }
  };

  const handleProviderSuccess = (dacProcessingTime: number) => {
    setProcessingTime(dacProcessingTime);
    checkState();
  };

  const handleFinApiSuccess = async () => {
    try {
      const fetchDacResult = await ServiceGateway.fetchFinApiDac(kwsState!.id);
      handleProviderSuccess(fetchDacResult.data.processingTime);
    } catch (error: any) {
      checkIbanEquality(error, DacProvider.FIN_API);
    }
  };

  const handleTinkSuccess = async () => {
    try {
      const fetchDacResult = await ServiceGateway.fetchTinkDac(kwsState!.id);
      handleProviderSuccess(fetchDacResult.data.processingTime);
    } catch (error: any) {
      checkIbanEquality(error, DacProvider.TINK);
    }
  };

  const initFinApiProcess = async () => {
    setProviderLoading(true);

    try {
      const finApiResponse = await ServiceGateway.initFinApi(
        kwsState!.id,
        window.wf_deviceoutput as DeviceOutputEnum,
      );

      if (finApiResponse.data.status === 'COMPLETED') {
        handleFinApiSuccess();
      } else {
        setFinApiToken(finApiResponse.data.id);
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setProviderError(true);
      }
    } finally {
      setTimeout(() => {
        setProviderLoading(false);
      }, 2000);
    }
  };

  const initTinkProcess = async () => {
    setProviderLoading(true);

    try {
      const wizardKey = await ServiceGateway.initTink(kwsState!.id);
      setTinkWizardKey(wizardKey.data);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setProviderError(true);
      }
    } finally {
      setTimeout(() => {
        setProviderLoading(false);
      }, 1000);
    }
  };

  const renewFinApiProcess = async () => {
    setProviderLoading(true);
    setFinApiToken(undefined);
    await ServiceGateway.finApiError(kwsState!.id);
    setProviderLoading(false);
    initFinApiProcess();
  };

  const renewTinkProcess = async () => {
    setTinkWizardKey(undefined);
    await ServiceGateway.resetTink(kwsState!.id);
    initTinkProcess();
  };

  const handleEqualIbanError = () => {
    const provider = ibanEqualError?.provider;
    if (provider === DacProvider.TINK) {
      renewTinkProcess();
    }
    if (provider === DacProvider.FIN_API) {
      renewFinApiProcess();
    }

    setIbanEqualError(undefined);
  };

  const initProvider = async () => {
    if (providerError) {
      setProviderError(false);
    }

    const selectedProvider = await ServiceGateway.getProvider(kwsState!.id);
    switch (selectedProvider.data) {
      case DacProvider.TINK:
        await initTinkProcess();
        break;
      case DacProvider.FIN_API:
        await initFinApiProcess();
        break;
      default:
        Sentry.captureMessage(`unknown provider: ${selectedProvider.data}`);
        await initTinkProcess();
        break;
    }
  };

  const selectBank = async (bank?: PossibleBank) => {
    if (bank) {
      setBankData(bank);
      setBankSearchText('');
      setPossibleBankList([]);
      setBankErrorMessage(undefined);

      setTinkWizardKey(undefined);
      setFinApiToken(undefined);

      try {
        await ServiceGateway.attachBankCodeSource(kwsState!.id, bank.code);
        initProvider();

        setTimeout(() => {
          const dacContainer = document.getElementsByClassName(
            'bank-search-container',
          )?.[0] as HTMLElement;
          scrollToElement(dacContainer);
        }, 500);
      } catch (bankSelectionError: any) {
        setProviderLoading(false);
        const errorData = bankSelectionError?.response?.data?.errors?.[0]?.message;
        if (errorData) {
          setBankErrorMessage(errorData);
        }
      }
      setBankSelectorDisplay(renderBankSelectorDisplay(bank));
    }
  };

  const getDacPageData = async () => {
    const result = await ServiceGateway.getDacPage(kwsState!.id);
    setDacPageModel(result.data);

    const oldBankCode = result.data.oldBank?.bankCode;
    if (oldBankCode) {
      // fix bank code if there are spaces
      const cleanedBankCode = oldBankCode.replace(/\s+/g, '');

      const { data: bankList } = await ServiceGateway.getPossibleBankList(cleanedBankCode);
      const selectedBank = bankList && bankList.find((bank) => cleanedBankCode === bank.code);

      let bank: PossibleBank = {
        name: '',
        code: cleanedBankCode,
      };

      if (selectedBank?.name) {
        bank = { ...bank, name: selectedBank.name };
      }

      // Name fix for test banks
      if (!isProd() && !bank.name) {
        bank = testBankList.find((testBank) => cleanedBankCode === testBank.code)!;
      }

      selectBank(bank);
    }
  };

  useEffect(() => {
    if (expandManual) {
      const manualKwsElement = document.getElementsByClassName('manual-kws')?.[0] as HTMLElement;
      scrollToElement(manualKwsElement);
    }
  }, [expandManual]);

  useEffect(() => {
    getDacPageData();
  }, []);

  return (
    <div className='dac-page'>
      {dacPageModel && (
        <WelcomeSection customerData={dacPageModel.customerData} newBank={dacPageModel.newBank} />
      )}

      <FunctionSection />

      <Container>
        <div className='bank-search-container' data-testid='bank-search-container'>
          <div className='title'>Bei welcher Bank führen Sie Ihr bisheriges Girokonto?</div>
          <div className='bank-search'>
            <AutoComplete
              searchText={bankSearchText}
              setSearchText={setBankSearchWithIbanValidation}
              displayedValue={bankSelectorDisplay}
              errorMessage={bankErrorMessage}
              setErrorMessage={setBankErrorMessage}
              possibleInputList={possibleBankList}
              selectPossibleInput={selectBank}
              renderItemDisplayValue={renderPossibleBankDisplayValue}
              setPossibleInputList={setPossibleBankList}
              placeholder='Bankname, BLZ, BIC oder IBAN'
              loading={loadingBanks}
            />
          </div>
        </div>
      </Container>

      {bankData && !bankErrorMessage && (
        <Container>
          <div className='dac-container' data-testid='dac-container'>
            <div className='section-header'>
              <div className='header-block'>
                <h2>Verbindung mit Ihrem Bankkonto</h2>
                <Tooltip tooltipSectionName={TooltipStateEnum.DAC_HEADER}>
                  <p>
                    Die Anmeldung mit den Online-Banking Zugangsdaten Ihres bisherigen Kontos ist
                    TÜV-zertifiziert und notwendig, um anhand Ihrer Kontoumsätze relevante
                    Zahlungspartner und Daueraufträge für Ihren Kontoumzug zu ermitteln. Nachdem Sie
                    die Auswahl überprüft und bei Bedarf angepasst haben, kann Ihr Kontoumzug
                    automatisch ausgeführt werden. Außerdem können Sie die Schließung Ihres alten
                    Kontos beauftragen. Sie behalten über jeden Umzugsschritt die volle Kontrolle.
                  </p>
                  <br />
                  <p>
                    Ihre Daten werden ausschließlich in verschlüsselter Form an Ihre Bank
                    übermittelt. Ihre Log-In Daten werden nicht gespeichert. Sensible Daten wie
                    Benutzername oder Passwort sind für CHECK24 zu keinem Zeitpunkt einsehbar.
                  </p>
                </Tooltip>
              </div>
              {providerError && (
                <InfoBox
                  title='Bank Login derzeit nicht möglich'
                  button={{ label: 'neu laden', onClick: initProvider }}
                  type='alert'
                >
                  <p>
                    Aufgrund von Wartungsarbeiten kann der Zugang zu Ihrem Bankkonto derzeit nicht
                    hergestellt werden. Bitte versuchen Sie es später erneut, um den Kontoumzug
                    fortzusetzen. Für Rückfragen stehen wir Ihnen gerne unter 089 - 24 24 11 09 zur
                    Verfügung.
                  </p>
                </InfoBox>
              )}
            </div>
            {providerLoading && (
              <div className='spinner-container'>
                <Spinner />
              </div>
            )}
            <div className={providerLoading ? 'hidden-while-loading' : ''}>
              {tinkWizardKey && !finApiToken && (
                <TinkContainer
                  tinkToken={tinkWizardKey}
                  onSuccess={handleTinkSuccess}
                  onAbort={renewTinkProcess}
                />
              )}
              {finApiToken && !tinkWizardKey && (
                <FinApiContainer
                  finApiToken={finApiToken}
                  onSuccess={handleFinApiSuccess}
                  onAbort={renewFinApiProcess}
                />
              )}
            </div>

            <Toggle
              text='Kontoumzug ohne Online-Banking'
              onClick={() => setExpandManual(!expandManual)}
              expandProp={expandManual}
            />
          </div>
        </Container>
      )}

      {expandManual && <ManualKWS manualKwsRef={manualKwsRef} />}

      <ContactCard />

      {processingTime ? (
        <ProgressBar expectedWaitingTimeInSeconds={processingTime} done={processingCompleted} />
      ) : null}

      {ibanEqualError && (
        <ConfirmationModal
          onCancel={handleEqualIbanError}
          onSubmit={handleEqualIbanError}
          submitLabel='zurück zum Login'
        >
          <div className='iban-equal-modal' data-testid='iban-equal-modal'>
            <p>{ibanEqualError.title}</p>
            <p>{ibanEqualError.message}</p>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default Dac;
