import React, { useEffect, useState } from 'react';
import Button from '../../../../Common/Button';
import Modal from '../../../../Common/Modal';
import { ReactComponent as InfoIcon } from '../../../../../assets/info.svg';
import './index.scss';
import {
  C24UrlGenerator,
  giroLinkGenerator,
  kundenUrlGenerator,
} from '../../../utils/linkGenerator';
import RadioButtonGroup, {
  RadioButtonGroupLayoutDirection,
} from '../../../../Common/RadioButtonGroup';
import { EnvironmentEnum } from '../../../../../utils/environment';

enum PartnerEnum {
  C24 = 'C24',
  OTHER = 'OTHER',
}

interface RedirectionModalProps {
  onClose: () => void;
}

const RedirectionModal = ({ onClose }: RedirectionModalProps) => {
  const [partner, setPartner] = useState<PartnerEnum>();
  const [error, setError] = useState(false);
  const environment = process.env.REACT_APP_ENV as EnvironmentEnum;

  const onChange = (value: PartnerEnum) => {
    setPartner(value);
  };

  const getButtonName = () => {
    switch (partner) {
      case PartnerEnum.C24:
        return 'zur C24 Bank';
      case PartnerEnum.OTHER:
        return 'zum Kundenbereich';
      default:
        return 'weiter';
    }
  };

  const submit = () => {
    if (!partner) {
      setError(true);
    } else {
      let generatedURL;

      if (partner === PartnerEnum.C24) {
        generatedURL = C24UrlGenerator();
      } else {
        generatedURL = kundenUrlGenerator();
      }

      window.open(generatedURL.redirectUrl, generatedURL.target);
    }
  };

  useEffect(() => {
    if (partner) {
      setError(false);
    }
  }, [partner]);

  const radioButtons = [
    {
      onChange,
      name: 'c24',
      label: 'C24 Bank',
      value: PartnerEnum.C24,
      checked: partner === PartnerEnum.C24,
    },

    {
      onChange,
      name: 'other',
      label: 'Andere Bank (z. B. ING, DKB, HVB, andere)',
      value: PartnerEnum.OTHER,
      checked: partner === PartnerEnum.OTHER,
    },
  ];

  return (
    <div className='redirection-modal'>
      <Modal onClose={onClose} appearance='bottom'>
        <div className='redirection-modal-content'>
          <p className='title'>Bank auswählen</p>
          <p className='sub-title'>Bei welcher Bank haben Sie Ihr neues Girokonto abgeschlossen?</p>

          <RadioButtonGroup
            buttons={radioButtons}
            hasError={error}
            layoutDirection={RadioButtonGroupLayoutDirection.VERTICAL}
          />

          {error ? <p className='error-text'>Bitte wählen Sie eine Bank aus</p> : null}
          {partner ? (
            <div className='info'>
              <InfoIcon className='icon' />
              <p>
                {partner === PartnerEnum.C24 ? (
                  <>
                    <b>Hinweis:</b> Sie werden direkt in die C24 Bank App weitergeleitet und können
                    Ihren Kontoumzug dort starten. Voraussetzung zur Nutzung des Kontoumzugs ist die
                    erfolgreiche Kontoeröffnung. Sollten Sie noch kein neues Girokonto beantragt
                    haben, gelangen Sie hier{' '}
                    {giroLinkGenerator('zum Girokontovergleich', environment)}.
                  </>
                ) : (
                  <>
                    <b>Hinweis:</b> Sie werden in den Kundenbereich weitergeleitet und können Ihren
                    Kontoumzug dort nach erfolgreicher Legitimation starten. Ohne Kundenkonto
                    beantragte Girokonten erhalten automatisch Zugang per E-Mail. Sollten Sie noch
                    kein neues Girokonto beantragt haben, gelangen Sie hier{' '}
                    {giroLinkGenerator('zum Girokontovergleich', environment)}.
                  </>
                )}
              </p>
            </div>
          ) : null}

          <div className='buttons'>
            <Button classNames='secondary' onClick={onClose} label='abbrechen' />
            <Button onClick={submit} label={getButtonName()} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RedirectionModal;
