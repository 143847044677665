import React from 'react';

const highlightSearchText = (text: string, searchText: string): JSX.Element => {
  const searchParts = searchText?.split(/[-\s]+/) || []; // hyphens or spaces
  const regex = new RegExp(`(${searchParts.join('|')})`, 'gi'); // case insensitive

  return (
    <span data-testid='search-field-item'>
      {searchText
        ? text.split(regex).map((part, index) =>
            searchParts.some((searchPart) => searchPart.toLowerCase() === part.toLowerCase()) ? (
              // eslint-disable-next-line react/no-array-index-key
              <b key={part + index}>{part}</b>
            ) : (
              part
            ),
          )
        : text}
    </span>
  );
};

export default highlightSearchText;
