import React, { useState, useEffect } from 'react';
import './index.scss';

import ContactCard from '../../../Common/ContactCard';
import Container from '../../../Common/Container';
import Checkbox from '../../../Common/Checkbox';
import Transaction from './components/Transaction';
import { ValidationResult } from '../../../../utils/validators';
import { ReactComponent as CheckIcon } from '../../../../assets/check.svg';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import DateInput from '../../../Common/DateInput';
import ServiceGateway from '../../../../services/ServiceGateway';
import { formatDate, isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import { ItemState } from '../../../../types/Overview/Common';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import { NotificationModel } from '../../../../types/Overview/NotificationModel';
import ActionButtons from '../../../Common/ActionButtons';
import InfoBox from '../../../Common/InfoBox';
import { StandingOrderModel } from '../../../../types/Overview/StandingOrderModel';
import ResetButton, { RESET_BUTTON_INTERSECTION } from '../../../Common/ResetButton';
import InnerBorderContainer from '../../../Common/InnerBorderContainer';
import AccountClosingItem from '../../CommonSections/AccountClosing/components/AccountClosingItem';
import Tooltip from '../../../Common/Tooltip';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import ConfirmationModal from '../../CommonSections/ConfirmationModal';
import { IntersectionObserverComponent } from '../../../../hooks/useIntersectionObserver';
import { FrontEndSection } from '../../../../stores/FrontendRule/FrontendRule.type';
import { useFrontendRuleStore } from '../../../../stores/FrontendRule/FrontendRule.store';

const SwitchingDateDetails = () => {
  const { kwsState, setSwitchingDateToProcess } = useKwsState();
  const decideSectionToShow = useFrontendRuleStore((state) => state.decideSectionToShow);
  const { overviewData, fillOverviewData } = useOverview();
  const { closingData, globalDate, notifications, standingOrders } = overviewData!;

  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});
  const [globalDateState, setGlobalDateState] = useState(globalDate.globalSwitchingDate);
  const [asapState, setAsapState] = useState(globalDate.atLeastOneActiveAsap);
  const [hasConfirmationModal, setHasConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);

  const notifyNotifications = notifications.filter(
    (x: NotificationModel) => x.state === ItemState.NOTIFY,
  );
  const notifyOrDisabledSO = standingOrders.filter(
    (x: StandingOrderModel) => x.state === ItemState.NOTIFY || x.state === ItemState.DISABLED,
  );
  const isAccountClosingShown = closingData.state === ItemState.NOTIFY;

  const submit = async () => {
    setIsLoading(true);
    try {
      const data = {
        globalSwitchingDate: globalDateState,
        asap: asapState,
      };
      const result = await ServiceGateway.editSwitchingDate(kwsState!.id, data);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setSwitchingDateToProcess(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce((acc: any, err: any) => {
              if (err.type === 'FIELD') {
                return {
                  ...acc,
                  globalDate: { valid: false, message: err.message },
                };
              }
              return acc;
            }, {}),
          });
        }
      }
    }
    setHasConfirmationModal(false);
    setIsLoading(false);
  };

  const handleSubmit = () => {
    if (!globalDate.allSwitchingDatesMatch) {
      setHasConfirmationModal(true);
      return;
    }

    submit();
  };

  const getOverviewData = async () => {
    const response = await ServiceGateway.getOverviewPage(kwsState!.id);
    fillOverviewData(response.data);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setGlobalDateState(globalDate.earliestSwitchingDate);
    }
    setAsapState(checked);
    setValidationError({});
  };

  const goBack = () => {
    setSwitchingDateToProcess(false);
  };

  const errorCheck = !(
    globalDateState && Object.values(validationError).every((item) => item.valid)
  );

  useEffect(() => {
    scrollToPosition('0');
    getOverviewData();
  }, []);

  return (
    <div className='switchingdate-details' data-testid='switchingdate-details'>
      <ResetButton
        title='zurück zur Übersicht'
        onClick={goBack}
        isSticky={!isDesktopOrTablet()}
        hasShadow={hasShadow}
      />

      <div className='intro-wrapper'>
        <Container>
          <div className='switchingdate-details-header'>
            <div className='title'>Kontoumzugsdatum</div>
            <div className='date'>
              {globalDate.allSwitchingDatesMatch ? '' : 'ab'}{' '}
              {formatDate(globalDate.globalSwitchingDate)}
            </div>
          </div>
        </Container>
      </div>
      {/* creates shadow for reset button after intro finished */}
      {!isDesktopOrTablet() && (
        <IntersectionObserverComponent
          onIntersection={setHasShadow}
          placeOfIntersection={RESET_BUTTON_INTERSECTION}
        />
      )}

      {/* Form */}
      <div className='form-container'>
        <div className='description'>
          Sie können den Stichtag frei wählen, ab dem Ihre Zahlungspartner Ihr neues Konto nutzen
          sollen.
        </div>

        {/* New Account */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Umzugsdatum für alle Zahlungspartner</div>
            <Tooltip tooltipSectionName={TooltipStateEnum.SWITCHING_DATE_HEADER} hasBackground>
              <p>
                Standardmäßig wird das frühestmögliche Datum für alle Zahlungspartner ausgewählt.
                Sie können dieses Datum hier anpassen und bei Bedarf ein späteres auswählen.
              </p>
              <br />
              <p>
                Sie können auch für jeden einzelnen Zahlungspartner ein bestimmtes Datum wählen, ab
                dem Ihr neues Konto genutzt werden soll. Dieses passen Sie in den Mitteilungsdetails
                des Zahlungspartners an.
              </p>
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='date-wrapper'>
              <div className='asap-row'>
                <Checkbox
                  onChange={onCheck}
                  checked={asapState}
                  inputId='switching-date'
                  label='Schnellstmöglich informieren'
                />
              </div>
              <DateInput
                value={globalDateState}
                onChange={setGlobalDateState}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    globalDate: e,
                  }))
                }
                validation={validationError.globalDate}
                disabled={asapState}
              />
            </div>
            {!asapState && (
              <div className='switching-info'>
                Durch die Änderung des Umzugsdatums ändert sich das Datum der Kontoschließung, falls
                diese gewünscht ist. So werden mögliche Rückbuchungen vermieden.
              </div>
            )}
          </div>
          <InfoBox type='info'>
            <p>
              CHECK24 informiert Ihre Zahlungspartner rechtzeitig über Ihre neue Bankverbindung. In
              der Regel benötigen Zahlungspartner eine gewisse Zeit, um Ihren Kontoumzug zu
              bestätigen. Ein früheres Umzugsdatum ist deshalb nicht auswählbar.
            </p>
          </InfoBox>
        </div>
      </div>

      <ActionButtons
        isDisabled={errorCheck}
        isLoading={isLoading}
        proceedButton={{ label: 'speichern', onClick: handleSubmit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      {(notifyNotifications.length > 0 ||
        notifyOrDisabledSO.length > 0 ||
        isAccountClosingShown) && (
        <Container classNames='transparent'>
          {notifyNotifications.length > 0 && (
            <>
              <div className='transactions-title'>Übersicht der Umzugsdaten</div>
              <div className='description'>
                Sie können individuell wählen, welches Kontoumzugsdatum welchen Zahlungspartner
                kommuniziert werden soll.
              </div>
              <InnerBorderContainer classNames='bg-white'>
                {notifyNotifications.map((trans: NotificationModel) => (
                  <Transaction data={trans} key={trans.id} />
                ))}
              </InnerBorderContainer>
            </>
          )}
          {decideSectionToShow(FrontEndSection.STANDING_ORDERS) &&
            notifyOrDisabledSO.length > 0 && (
              <>
                <div className='transactions-title'>Übersicht der Daueraufträge</div>
                <div className='description'>
                  Sie können individuell wählen, an welchem Datum Daueraufträge eingerichtet werden
                  sollen.
                </div>
                <InnerBorderContainer classNames='bg-white'>
                  {notifyOrDisabledSO.map((trans: StandingOrderModel) => (
                    <Transaction data={trans} key={trans.id} />
                  ))}
                </InnerBorderContainer>
              </>
            )}

          {isAccountClosingShown && (
            <>
              <div className='transactions-title'>Schließung des bisherigen Kontos</div>
              <AccountClosingItem />
              <div className='warnings check'>
                <CheckIcon className='modified-icon' />
                <p>
                  Die Schließung des bisherigen Kontos erfolgt erst wenn der Kontoumzug
                  abgeschlossen ist.
                </p>
              </div>
            </>
          )}
        </Container>
      )}

      <ContactCard />

      {hasConfirmationModal && (
        <ConfirmationModal
          onCancel={() => setHasConfirmationModal(false)}
          onSubmit={submit}
          submitLabel='überschreiben'
          cancelButtonLabel='zurück'
          hasCancelButton
        >
          <div className='switching-alert-content'>
            <p>Individuelle Umzugsdaten überschreiben</p>
            <p>
              Wenn Sie das Kontoumzugsdatum für alle Zahlungspartner speichern, überschreiben Sie
              Ihre zuvor ausgewählten individuellen Umzugsdaten.
            </p>
            <p>
              <b>Hinweis:</b> Es wird ein Umzugsdatum allen Zahlungspartnern kommuniziert.
            </p>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

export default SwitchingDateDetails;
