import React from 'react';
import './index.scss';
import { ReactComponent as CarretIcon } from '../../../assets/carret.svg';

export const RESET_BUTTON_INTERSECTION = '-76px 0px 0px 0px';

type ResetButtonProps = {
  onClick: () => void;
  title: string;
  isLoading?: boolean;
  /** Stick to the top of the page (the parent block components top)  */
  isSticky?: boolean;
  hasShadow?: boolean;
};

/** The primary serve is as a go back button  */
const ResetButton = ({ onClick, title, isLoading, isSticky, hasShadow }: ResetButtonProps) => (
  <div
    onClick={onClick}
    className={`reset-button${isSticky ? ' sticky' : ''}${hasShadow ? ' shadow' : ''}`}
    data-testid='reset-button'
  >
    <CarretIcon className='rotate-270' />
    {title}
    {isLoading && <div className='blue-spinner' data-testid='reset-spinner' />}
  </div>
);

export default ResetButton;
