import React from 'react';
import ContactCard from '../../Common/ContactCard';
import { ReactComponent as BlueLogo } from '../../../assets/blue-logo.svg';
import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { ReactComponent as CrossIcon } from '../../../assets/cross.svg';
import './index.scss';

interface RestrictedProps {
  success?: boolean;
  message: string;
}

const Restricted: React.FC<RestrictedProps> = ({ success, message }: RestrictedProps) => (
  <div className='restricted-page' data-testid='restricted-page'>
    <div className='context'>
      <BlueLogo className='blue-logo' />
      <div className='line'>
        {success ? (
          <CheckIcon className='icon success' data-testid='success-icon' />
        ) : (
          <CrossIcon className='icon failed' data-testid='fail-icon' />
        )}
        <p>{message}</p>
      </div>
    </div>

    <ContactCard />
  </div>
);

export default Restricted;
