import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useKwsState } from '../../../../../../contexts/KwsStateContext/KwsStateContext';
import Validators, { ValidationErrorNotification } from '../../../../../../utils/validators';
import {
  NotificationDetailsModel,
  EditNotificationModel,
} from '../../../../../../types/Overview/NotificationModel';
import ServiceGateway from '../../../../../../services/ServiceGateway';
import { Gender, RecipientSearchModel } from '../../../../../../types/Overview/Common';
import Tooltip from '../../../../../Common/Tooltip';
import Checkbox from '../../../../../Common/Checkbox';
import DateInput from '../../../../../Common/DateInput';
import InfoBox from '../../../../../Common/InfoBox';
import TextInput from '../../../../../Common/TextInput';
import { WorkflowEnum } from '../../../../../../contexts/KwsStateContext/KwsState.types';
import AddressInput from '../../../../../Common/AddressInput';
import ActionButtons from '../../../../../Common/ActionButtons';
import ContactCard from '../../../../../Common/ContactCard';
import './index.scss';
import { TooltipStateEnum } from '../../../../../../contexts/TooltipStateContext/Tooltip.types';
import AutoComplete from '../../../../../Common/Autocomplete';
import { setModifiedTransaction } from '../../../../../../utils/helper';
import useDebounce from '../../../../../../hooks/useDebounce';
import RecipientSearchItem from '../../../../../Common/Autocomplete/components/RecipientSearchItem';
import ChipButtonGroup from '../../../../../Common/ChipButtonGroup';

type NotificationDetailTabProps = {
  initialValidationError: ValidationErrorNotification;
  notificationDetailState: NotificationDetailsModel;
  setNotificationDetailState: React.Dispatch<
    React.SetStateAction<NotificationDetailsModel | undefined>
  >;
};

const NotificationDetailTab = ({
  notificationDetailState,
  setNotificationDetailState,
  initialValidationError,
}: NotificationDetailTabProps) => {
  const { kwsState, notificationToProcess, setNotificationToProcess, setSwitchingDateToProcess } =
    useKwsState();
  const { pageType } = notificationToProcess!;
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [validationError, setValidationError] =
    useState<ValidationErrorNotification>(initialValidationError);

  const [isRecipientFillLoading, setIsRecipientFillLoading] = useState(false);
  const [possibleInputList, setPossibleInputList] = useState<RecipientSearchModel[]>([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [selectedInputName, setSelectedInputName] = useState<string | undefined>(
    notificationDetailState.recipient?.name,
  );
  const debouncedSearchText = useDebounce(notificationDetailState.recipient?.name, 500);

  const [firstSwitchingDate, setFirstSwitchingDate] = useState<string>(
    notificationDetailState.dates?.switchingDate,
  );

  const queryClient = useQueryClient();
  const { mutate: onSubmit, isPending: isActionButtonsLoading } = useMutation({
    mutationFn: async () => {
      const data: EditNotificationModel = {
        ...notificationDetailState,
        asap: notificationDetailState.dates?.asap,
        switchingDate: notificationDetailState.dates?.asap
          ? notificationDetailState.dates?.earliestSwitchingDate
          : notificationDetailState.dates?.switchingDate,
        recipient: {
          ...notificationDetailState.recipient,
          email: notificationDetailState.recipient?.email || undefined,
          fax: notificationDetailState.recipient?.fax || undefined,
          address: {
            ...notificationDetailState.recipient?.address,
            countryCode: notificationDetailState.recipient?.address?.countryCode || 'DE',
            country: notificationDetailState.recipient?.address?.country || 'DEUTSCHLAND',
          },
        },
      };

      let response;
      if (pageType === 'EDIT') {
        response = await ServiceGateway.updateNotification(kwsState!.id, data);
      } else {
        response = await ServiceGateway.createNotification(kwsState!.id, data);
      }

      return response.data;
    },
    onSuccess: (data) => {
      if (pageType === 'EDIT') {
        queryClient.invalidateQueries({ queryKey: ['notification-details'] });
      }
      setModifiedTransaction(data.id);
      setNotificationToProcess(undefined);
      setSwitchingDateToProcess(false);
    },
    onError: (error: any) => {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce(
              (acc: any, err: any) => ({
                ...acc,
                [err.type]: {
                  ...acc[err.type],
                  [err.key]: { valid: false, message: err.message },
                },
              }),
              {},
            ),
          });
        }
      }
    },
  });

  // telekom temporary disable edit
  const checkIfTelekom = () => {
    if (
      notificationDetailState.added === false &&
      (notificationDetailState?.recipient?.id === 79589 ||
        notificationDetailState?.recipient?.id === 163487)
    ) {
      return true;
    }

    return false;
  };
  const [isTelekomEditDisabled] = useState(() => checkIfTelekom());

  useEffect(() => {
    const hasCustomerError =
      Object.keys(validationError).length === 0
        ? false
        : !(
            Object.keys(validationError?.FIELD ?? {})
              .filter((item) => item.indexOf('customerData') >= 0)
              .every((item) => validationError?.FIELD[item].valid) &&
            (validationError?.ADDRESS?.customerData?.valid ?? false)
          );
    if (hasCustomerError) {
      setShowUserDetail(true);
    }
  }, [validationError]);

  const onGenderSelect = (val: Gender) => {
    setNotificationDetailState({
      ...notificationDetailState,
      customerData: {
        ...notificationDetailState.customerData,
        gender: val,
      },
    });
  };

  const goBack = () => {
    setNotificationToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  const onCheck = (checked: boolean) => {
    if (checked) {
      setFirstSwitchingDate(notificationDetailState.dates?.switchingDate);
    }
    setValidationError((validations) => ({
      ...validations,
      FIELD: {
        ...validations?.FIELD,
        switchingDate: { valid: true },
      },
    }));
    setNotificationDetailState({
      ...notificationDetailState,
      dates: {
        ...notificationDetailState.dates,
        asap: checked,
        switchingDate: checked
          ? notificationDetailState.dates.earliestSwitchingDate
          : firstSwitchingDate,
      },
    });
  };

  const hasGenderError =
    validationError.FIELD?.['customerData.gender'] &&
    !validationError.FIELD['customerData.gender'].valid;

  const errorCheck = !(
    notificationDetailState.dates.switchingDate &&
    notificationDetailState.recipient?.name &&
    (notificationDetailState.recipient?.address?.germanAddress === false
      ? true
      : (notificationDetailState.private
          ? notificationDetailState.recipient?.address?.street &&
            notificationDetailState.recipient?.address?.streetNum
          : true) &&
        notificationDetailState.recipient?.address?.city &&
        notificationDetailState.recipient?.address?.postalCode) &&
    notificationDetailState.customerData?.gender &&
    notificationDetailState.customerData?.lastName &&
    notificationDetailState.customerData?.firstName &&
    notificationDetailState.customerData?.mobileNumber &&
    notificationDetailState.customerData?.birthDate &&
    notificationDetailState.customerData?.email &&
    notificationDetailState.customerData?.address?.city &&
    notificationDetailState.customerData?.address?.zipCode &&
    Object.values(validationError?.FIELD ?? {}).every((item) => item.valid) &&
    Object.values(validationError?.ADDRESS ?? {}).every((item) => item.valid)
  );

  let isAccountClosingDatePassed = false;
  if (notificationToProcess?.accountClosingDate) {
    const dateToCompare = moment(notificationToProcess.accountClosingDate).startOf('day');
    isAccountClosingDatePassed = dateToCompare.isBefore(moment().startOf('day'));
  }

  const chipButtons = [
    {
      label: 'Frau',
      onClick: onGenderSelect,
      value: Gender.FEMALE,
      isActive: notificationDetailState.customerData.gender === Gender.FEMALE,
      hasError: hasGenderError,
    },
    {
      label: 'Herr',
      onClick: onGenderSelect,
      value: Gender.MALE,
      isActive: notificationDetailState.customerData.gender === Gender.MALE,
      hasError: hasGenderError,
    },
    {
      label: 'Keine Angabe',
      onClick: onGenderSelect,
      value: Gender.NA,
      isActive: notificationDetailState.customerData.gender === Gender.NA,
      hasError: hasGenderError,
    },
  ];

  // #region recipient search
  const renderDisplayValue = (data: RecipientSearchModel): JSX.Element => (
    <RecipientSearchItem itemData={data} searchText={debouncedSearchText || ''} />
  );

  const renderSelectableDisplayValue = (value: string): JSX.Element => (
    <RecipientSearchItem
      // pass the extra information as street to use the same component
      itemData={{ name: value, street: 'Zahlungspartner manuell hinzufügen' }}
      searchText={value}
    />
  );

  const searchPossibleManualRecipients = async (searchText: string) => {
    setIsRecipientFillLoading(true);
    setSearchErrorMessage('');

    const response = await ServiceGateway.getPossibleRecipientList(searchText);

    setPossibleInputList(response.data);
    setSearchErrorMessage(response.data.length === 0 ? 'Zahlungspartner nicht verfügbar.' : '');
    setIsRecipientFillLoading(false);
  };

  const selectRecipient = async (recipient?: RecipientSearchModel) => {
    setSearchErrorMessage('');

    if (recipient?.name) {
      setNotificationDetailState({
        ...notificationDetailState,
        recipient: {
          ...notificationDetailState.recipient,
          name: recipient.name,
          id: recipient?.id,
          email: recipient?.email,
          fax: recipient?.fax,
          address: {
            ...notificationDetailState.recipient?.address,

            street: recipient?.street,
            streetNum: recipient?.streetNum,
            postalCode: recipient?.postalCode,
            city: recipient?.city,
            country: recipient?.country,
            postfach: recipient?.postfach,
          },
        },
      });
      setSelectedInputName(recipient.name);
      setPossibleInputList([]);
    } else {
      setSelectedInputName(notificationDetailState.recipient?.name);
      setPossibleInputList([]);
      setNotificationDetailState({
        ...notificationDetailState,
        recipient: {
          ...notificationDetailState.recipient,
          id: '',
          email: '',
          fax: '',
          address: {
            ...notificationDetailState.recipient?.address,
            street: '',
            streetNum: '',
            postalCode: '',
            city: '',
            country: '',
            postfach: '',
          },
        },
      });
    }
  };

  useEffect(() => {
    if (
      notificationDetailState.recipient?.name &&
      notificationDetailState.recipient?.name.length >= 2 &&
      notificationDetailState.recipient?.name !== selectedInputName
    ) {
      if (pageType === 'ADD' || notificationDetailState.added) {
        searchPossibleManualRecipients(debouncedSearchText!);
      }
    } else {
      setPossibleInputList([]);
    }
  }, [debouncedSearchText, selectedInputName]);
  // #endregion

  return (
    <div className='notification-details-tab'>
      <div className='form-container'>
        <div className='description'>
          {pageType === 'EDIT'
            ? 'Für die Benachrichtigung des Zahlungspartners nutzen wir die folgenden Daten. Sie können dies individuell anpassen.'
            : 'Bitte tragen Sie die Daten des Partners ein, den wir zusätzlich über Ihre neue Bankverbindung informieren sollen.'}
        </div>

        {/* Contact data payment partner */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Kontaktdaten Zahlungspartner</div>
            <Tooltip
              tooltipSectionName={TooltipStateEnum.NOTIFICATION_DETAILS_ADDRESS}
              hasBackground
            >
              {kwsState!.workflow === WorkflowEnum.MANUAL_ATTACH_IBAN ? (
                <p>
                  Tragen Sie hier die Kontaktdaten des Zahlungspartners ein.
                  <br />
                  <br />
                  Die Mitteilung an den Zahlungspartner über die Änderung Ihrer Bankverbindung wird
                  an diese Adresse geschickt.
                </p>
              ) : (
                <p>
                  Die vorausgefüllte Anschrift wurde automatisch ermittelt. Sie können die
                  Kontaktdaten des Zahlungspartners manuell anpassen. Diese erscheinen in der
                  Mitteilung an den Zahlungspartner über die Änderung Ihrer Bankverbindung.
                </p>
              )}
            </Tooltip>
          </div>
          <div className='form-input'>
            {pageType === 'EDIT' && !notificationDetailState.added ? (
              <TextInput
                className='w-full'
                value={notificationDetailState.recipient?.name ?? ''}
                onChange={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notificationDetailState.recipient,
                      name: v,
                    },
                  })
                }
                placeholder='Name'
                validator={Validators.moreThan2()}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      'recipient.name': e,
                    },
                  }))
                }
                validation={validationError.FIELD?.['recipient.name']}
                disabled={isTelekomEditDisabled}
              />
            ) : (
              <AutoComplete
                placeholder='Name'
                searchText={notificationDetailState.recipient?.name || ''}
                setSearchText={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notificationDetailState.recipient,
                      name: v,
                    },
                  })
                }
                displayedValue={notificationDetailState.recipient?.name || ''}
                loading={isRecipientFillLoading}
                possibleInputList={possibleInputList}
                errorMessage={searchErrorMessage}
                selectPossibleInput={selectRecipient}
                setPossibleInputList={setPossibleInputList}
                renderItemDisplayValue={renderDisplayValue}
                isSearchInputSelectable={
                  notificationDetailState.recipient?.name !== selectedInputName
                    ? {
                        renderSelectableDisplayValue,
                      }
                    : undefined
                }
              />
            )}
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.recipient?.department ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notificationDetailState.recipient,
                    department: v,
                  },
                })
              }
              placeholder='Namenszusatz'
              validator={Validators.moreThan2orNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.department': e,
                  },
                }))
              }
              validation={validationError.FIELD?.['recipient.department']}
              optional
              disabled={isTelekomEditDisabled}
            />
          </div>
          <div className='form-input'>
            {notificationDetailState.recipient?.address?.germanAddress === false ? (
              <TextInput
                className='w-full'
                value={notificationDetailState.recipient?.address?.street ?? ''}
                onChange={(v) =>
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notificationDetailState.recipient,
                      address: {
                        ...notificationDetailState.recipient?.address,
                        street: v,
                      },
                    },
                  })
                }
                placeholder='Anschrift'
                validator={Validators.moreThan2orNull()}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      'recipient.street': e,
                    },
                  }))
                }
                validation={validationError?.FIELD?.['recipient.street']}
              />
            ) : (
              <AddressInput
                withPostfach
                addressProps={{
                  street: notificationDetailState.recipient?.address?.street,
                  city: notificationDetailState.recipient?.address?.city,
                  streetNum: notificationDetailState.recipient?.address?.streetNum,
                  postalCode: notificationDetailState.recipient?.address?.postalCode,
                  postfach: notificationDetailState.recipient?.address?.postfach,
                }}
                validations={{
                  street: validationError.FIELD?.['recipient.address.street'],
                  city: validationError.FIELD?.['recipient.address.city'],
                  streetNum: validationError.FIELD?.['recipient.address.streetNum'],
                  postalCode: validationError.FIELD?.['recipient.address.postalCode'],
                  postfach: validationError.FIELD?.['recipient.address.postfach'],
                  fullAddress: validationError.ADDRESS?.recipient,
                }}
                onAddressChange={(v: any) => {
                  setNotificationDetailState({
                    ...notificationDetailState,
                    recipient: {
                      ...notificationDetailState.recipient,
                      address: {
                        ...notificationDetailState.recipient?.address,
                        ...v,
                      },
                    },
                  });
                  setValidationError((validations) => ({
                    ...validations,
                    ADDRESS: {
                      ...validations?.ADDRESS,
                      recipient: { valid: true },
                    },
                  }));
                }}
                onError={(e: any, fieldName: any) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      [`recipient.address.${fieldName}`]: e,
                    },
                  }))
                }
                showHintField={
                  pageType === 'ADD' ||
                  notificationDetailState.state === 'INCOMPLETE' ||
                  notificationDetailState.state === 'TO_COMPLETE'
                    ? 'INITIALLY'
                    : 'AFTER_FOCUS'
                }
                isPrivateNotification={notificationDetailState.private}
                isDisabled={isTelekomEditDisabled}
              />
            )}
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.recipient?.email ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notificationDetailState.recipient,
                    email: v,
                  },
                })
              }
              placeholder='Email'
              validator={Validators.emailOrNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.email': e,
                  },
                }))
              }
              inputMode='email'
              validation={validationError?.FIELD?.['recipient.email']}
              optional
              inputRegex={/[ ]/g}
              disabled={isTelekomEditDisabled}
            />
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.recipient?.fax ?? ''}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  recipient: {
                    ...notificationDetailState.recipient,
                    fax: v,
                  },
                })
              }
              placeholder='Fax'
              validator={Validators.phoneOrNull()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'recipient.fax': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['recipient.fax']}
              optional
              inputMode='tel'
              disabled={isTelekomEditDisabled}
            />
          </div>
        </div>

        {/* Reference number */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Ihre Referenznummer</div>
            <Tooltip
              tooltipSectionName={TooltipStateEnum.NOTIFICATION_DETAILS_REFERENCE_NUMBER}
              hasBackground
            >
              <p>
                Hier können Sie zum Beispiel Ihre Kundennummer, Referenznummer, Vertragsnummer oder
                Ähnliches eintragen, welche in der gewöhnlichen Kommunikation mit dem
                Zahlungspartner genutzt werden.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.referenceNumber}
              placeholder='Kundenreferenz'
              validator={Validators.moreThan2orNull()}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  referenceNumber: v,
                })
              }
              optional
            />
          </div>
        </div>

        {/* Switching date */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Umzugsdatum für diesen Zahlungspartner</div>
            <Tooltip
              tooltipSectionName={TooltipStateEnum.NOTIFICATION_DETAILS_HEADER}
              hasBackground
            >
              <p>
                Sie können für jeden einzelnen Zahlungspartner ein bestimmtes Datum wählen, ab dem
                Ihr neues Konto genutzt werden soll. Dieses erscheint in der Mittelung über die
                Änderung Ihrer Bankverbindung.
              </p>
            </Tooltip>
          </div>
          <div className='form-input switching-date'>
            <div className='date-wrapper'>
              <div className='asap-row'>
                <Checkbox
                  onChange={onCheck}
                  checked={notificationDetailState.dates?.asap}
                  inputId='asap-checkbox'
                  label='Schnellstmöglich informieren'
                />
              </div>
              <DateInput
                value={notificationDetailState.dates?.switchingDate}
                onChange={(v) => {
                  setNotificationDetailState({
                    ...notificationDetailState,
                    dates: {
                      ...notificationDetailState.dates,
                      switchingDate: v,
                    },
                  });
                }}
                validation={validationError?.FIELD?.switchingDate}
                onError={(e) =>
                  setValidationError((validations) => ({
                    ...validations,
                    FIELD: {
                      ...validations?.FIELD,
                      switchingDate: e,
                    },
                  }))
                }
                disabled={notificationDetailState.dates?.asap}
              />
            </div>
            {!notificationDetailState.dates?.asap && !isAccountClosingDatePassed && (
              <div className='switching-info'>
                Durch die Änderung des Umzugsdatums ändert sich das Datum der Kontoschließung, falls
                diese gewünscht ist. So werden mögliche Rückbuchungen vermieden.
              </div>
            )}
            {isAccountClosingDatePassed && (
              <div className='switching-info'>
                <b>Achtung:</b> die Schließung Ihres bisherigen Kontos liegt in der Vergangenheit.
                Es kann sein, dass dieser Zahlungspartner bis zum gewählten Kontoumzugsdatum nicht
                abbuchen kann.
              </div>
            )}
          </div>
          <InfoBox type='info'>
            <p>
              CHECK24 informiert Ihre Zahlungspartner rechtzeitig über Ihre neue Bankverbindung. In
              der Regel benötigen Zahlungspartner eine gewisse Zeit, um Ihren Kontoumzug zu
              bestätigen. Ein früheres Umzugsdatum ist deshalb nicht auswählbar.
            </p>
          </InfoBox>
        </div>

        {/* Personal data */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Ihre persönlichen Angaben</div>
            <Tooltip
              tooltipSectionName={TooltipStateEnum.NOTIFICATION_DETAILS_CUSTOMER}
              hasBackground
            >
              <p>
                Hier können Sie abweichende Kontaktdaten angeben. Zum Beispiel, falls der Vertrag
                mit dem Zahlungspartner auf dem Namen von Ihren Ehemann / Ehefrau / Kind läuft.
                <br />
                <br />
                Die Kontaktdaten, die Sie hier eintragen, erscheinen in der Mitteilung an den
                Zahlungspartner über die Änderung Ihrer Bankverbindung.
              </p>
            </Tooltip>
          </div>

          <div className='form-input'>
            <ChipButtonGroup buttons={chipButtons} groupLabel='Anrede' />
          </div>

          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.customerData?.firstName}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notificationDetailState.customerData,
                    firstName: v,
                  },
                })
              }
              placeholder='Vorname'
              validator={Validators.moreThan2()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.firstName': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['customerData.firstName']}
            />
          </div>
          <div className='form-input'>
            <TextInput
              className='w-full'
              value={notificationDetailState.customerData?.lastName}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notificationDetailState.customerData,
                    lastName: v,
                  },
                })
              }
              placeholder='Nachname'
              validator={Validators.moreThan2()}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.lastName': e,
                  },
                }))
              }
              validation={validationError?.FIELD?.['customerData.lastName']}
            />
          </div>
          <div className='form-label'>Geburtsdatum</div>
          <div className='form-input'>
            <DateInput
              value={notificationDetailState.customerData?.birthDate}
              onChange={(v) =>
                setNotificationDetailState({
                  ...notificationDetailState,
                  customerData: {
                    ...notificationDetailState.customerData,
                    birthDate: v,
                  },
                })
              }
              validation={validationError?.FIELD?.['customerData.birthDate']}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  FIELD: {
                    ...validations?.FIELD,
                    'customerData.birthDate': e,
                  },
                }))
              }
            />
          </div>
        </div>

        {showUserDetail && (
          <>
            {/* Customer Address */}
            <div className='form-group'>
              <div className='form-header'>
                <div className='title'>Ihre Anschrift</div>
              </div>
              <div className='form-input'>
                <AddressInput
                  addressProps={{
                    street: notificationDetailState.customerData?.address?.street,
                    city: notificationDetailState.customerData?.address?.city,
                    streetNum: notificationDetailState.customerData?.address?.streetNumber,
                    postalCode: notificationDetailState.customerData?.address?.zipCode,
                  }}
                  validations={{
                    street: validationError.FIELD?.['customerData.address.street'],
                    city: validationError.FIELD?.['customerData.address.city'],
                    streetNum: validationError.FIELD?.['customerData.address.streetNum'],
                    postalCode: validationError.FIELD?.['customerData.address.zipCode'],
                    fullAddress: validationError.ADDRESS?.customerData,
                  }}
                  onAddressChange={(v: any) => {
                    setNotificationDetailState(
                      (oldNotificationData: NotificationDetailsModel | undefined) => ({
                        ...oldNotificationData!,
                        customerData: {
                          ...oldNotificationData!.customerData,
                          address: {
                            ...oldNotificationData!.customerData.address,
                            street: v?.street,
                            city: v?.city,
                            streetNumber: v?.streetNum,
                            zipCode: v?.postalCode,
                          },
                        },
                      }),
                    );
                    setValidationError((validations) => ({
                      ...validations,
                      ADDRESS: {
                        ...validations?.ADDRESS,
                        customerData: { valid: true },
                      },
                    }));
                  }}
                  onError={(e: any, fieldName: any) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        [`customerData.address.${
                          fieldName === 'postalCode' ? 'zipCode' : fieldName
                        }`]: e,
                      },
                    }))
                  }
                />
              </div>
            </div>

            {/* Customer Contact data */}
            <div className='form-group'>
              <div className='form-header'>
                <div className='title'>Ihre Kontaktdaten</div>
              </div>
              <div className='form-input'>
                <TextInput
                  className='w-full'
                  value={notificationDetailState.customerData?.email}
                  onChange={(v) =>
                    setNotificationDetailState({
                      ...notificationDetailState,
                      customerData: {
                        ...notificationDetailState.customerData,
                        email: v,
                      },
                    })
                  }
                  placeholder='E-Mail'
                  validator={Validators.email()}
                  onError={(e) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        'customerData.email': e,
                      },
                    }))
                  }
                  validation={validationError?.FIELD?.['customerData.email']}
                  inputRegex={/[ ]/g}
                  inputMode='email'
                />
              </div>
              <div className='form-input'>
                <TextInput
                  className='w-full'
                  value={notificationDetailState.customerData?.mobileNumber}
                  onChange={(v) =>
                    setNotificationDetailState({
                      ...notificationDetailState,
                      customerData: {
                        ...notificationDetailState.customerData,
                        mobileNumber: v,
                      },
                    })
                  }
                  inputMode='tel'
                  placeholder='Mobilnummer'
                  validator={Validators.phone()}
                  validation={validationError?.FIELD?.['customerData.mobileNumber']}
                  onError={(e) =>
                    setValidationError((validations) => ({
                      ...validations,
                      FIELD: {
                        ...validations?.FIELD,
                        'customerData.mobileNumber': e,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>

      <ActionButtons
        isSticky
        isDisabled={errorCheck}
        isLoading={isActionButtonsLoading}
        proceedButton={{ label: 'Mitteilung speichern', onClick: onSubmit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default NotificationDetailTab;
