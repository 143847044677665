import React from 'react';
import './index.scss';
import Modal from '../../../Common/Modal';
import Button from '../../../Common/Button';

interface ConfirmationModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  submitLabel: string;
  children: React.ReactNode;
  isLoading?: boolean;
  hasCancelButton?: boolean;
  cancelButtonLabel?: string;
}

const ConfirmationModal = ({
  onCancel,
  onSubmit,
  submitLabel,
  children,
  isLoading,
  hasCancelButton,
  cancelButtonLabel,
}: ConfirmationModalProps): JSX.Element => (
  <div className='confirmation-modal-wrapper' data-testid='confirmation-modal-wrapper'>
    <Modal onClose={onCancel}>
      <div className='confirmation-modal'>
        <div className='content'>{children}</div>

        <div className='desktop-buttons'>
          {hasCancelButton && (
            <Button
              classNames='secondary'
              onClick={onCancel}
              label={cancelButtonLabel || 'abbrechen'}
              isDisabled={isLoading}
            />
          )}
          <Button onClick={onSubmit} label={submitLabel} loading={isLoading} />
        </div>
        <div
          className='mobile-button'
          onClick={() => {
            if (!isLoading) {
              onSubmit();
            }
          }}
        >
          {submitLabel}
          {isLoading && <div className='blue-spinner' />}
        </div>
      </div>
    </Modal>
  </div>
);

export default ConfirmationModal;
