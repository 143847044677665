import React, { useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = (rootMargin = '0px') => {
  const intersectionElementRef = useRef<HTMLDivElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsIntersecting(!entry.isIntersecting);
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (intersectionElementRef?.current) {
      observer = new IntersectionObserver(observerCallback, { rootMargin });
      observer.observe(intersectionElementRef.current);
    }
    return () => {
      if (intersectionElementRef?.current) {
        observer.unobserve(intersectionElementRef.current);
      }
    };
  }, []);

  return { intersectionElementRef, isIntersecting };
};

interface IntersectionComponentProps {
  onIntersection: (isIntersecting: boolean) => void;
  placeOfIntersection?: string;
}

export const IntersectionObserverComponent = ({
  onIntersection,
  placeOfIntersection,
}: IntersectionComponentProps): React.JSX.Element => {
  const { intersectionElementRef, isIntersecting } = useIntersectionObserver(placeOfIntersection);

  useEffect(() => {
    // pass the intersection value to parent component when changed
    onIntersection(isIntersecting);
  }, [isIntersecting]);

  return <div ref={intersectionElementRef} />;
};
