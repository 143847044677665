import React from 'react';
import './index.scss';
import Radio from '../Radio';

export enum RadioButtonGroupLayoutDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type RadioProps = {
  value: any;
  name: string;
  disabled?: boolean;
  className?: string;
  label: string;
  onChange: (val: any) => void;
  checked: boolean;
};

type RadioButtonGroupProps = {
  /** It takes only an array of RadioButton Components */
  buttons: RadioProps[];
  hasError?: boolean;
  groupLabel?: string;
  /** Vertical | Horizontal layouts present */
  layoutDirection?: RadioButtonGroupLayoutDirection;
  /** Container border is present except in Sort buttons */
  isWithoutBorder?: boolean;
};

const RadioButtonGroup = ({
  buttons,
  hasError,
  layoutDirection,
  groupLabel,
  isWithoutBorder,
}: RadioButtonGroupProps) => (
  <div className='radio-button-group-container'>
    {groupLabel ? <div className='group-label'>{groupLabel}</div> : null}
    <div
      className={`radio-button-group ${hasError ? 'error' : ''} ${
        isWithoutBorder ? 'no-border-layout' : ''
      }`}
      data-testid='radio-button-group'
    >
      <div
        className={`radio-buttons ${
          buttons.length > 6 || layoutDirection === RadioButtonGroupLayoutDirection.VERTICAL
            ? RadioButtonGroupLayoutDirection.VERTICAL
            : `${
                layoutDirection
                  ? `horizontal grid-cols-${buttons.length}`
                  : `grid-cols-${buttons.length}`
              }`
        }`}
        data-testid='radio-buttons'
      >
        {buttons.map((btn) => (
          <Radio
            value={btn.value}
            name={btn.name}
            label={btn.label}
            onChange={btn.onChange}
            checked={btn.checked}
            disabled={btn?.disabled}
            className={btn?.className}
            key={btn.label}
          />
        ))}
      </div>
    </div>
  </div>
);

export default RadioButtonGroup;
